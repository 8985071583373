document.addEventListener('DOMContentLoaded', function() {
    const timestamp = new Date().getTime();

    // すべての<source>要素にパラメータを追加
    const sources = document.querySelectorAll('picture source');
    sources.forEach((source) => {
        const srcset = source.getAttribute('srcset');
        if (srcset) {
            const newSrcset = srcset
                .split(',')
                .map(src => {
                    // 各候補の先頭・末尾の空白をトリム
                    const trimmedSrc = src.trim();
                    // URLと解像度の区切り（例: "image.jpg 2x" の "2x" 部分）を分離
                    const [url, descriptor] = trimmedSrc.split(' ');
                    // パラメータを追加し、区切りも合わせて戻す
                    const newUrl = url.includes('?') 
                        ? `${url}&v=${timestamp}`
                        : `${url}?v=${timestamp}`;
                    return descriptor ? `${newUrl} ${descriptor}` : newUrl;
                })
                .join(',');  // カンマ区切りを保持
            source.setAttribute('srcset', newSrcset);
        }
    });

    // すべての<img>要素にパラメータを追加
    const images = document.querySelectorAll('img');
    images.forEach((img) => {
        const src = img.getAttribute('src');
        if (src) {
            const newSrc = src.includes('?') ? `${src}&v=${timestamp}` : `${src}?v=${timestamp}`;
            img.setAttribute('src', newSrc);
        }
    });
});
