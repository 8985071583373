(() => {

    const $modalsButton = document.querySelectorAll('.js_modalButton');
    const $html = document.querySelector('html');

    $modalsButton.forEach((modalOpenBtn, index) => {

        const ariaControls = modalOpenBtn.getAttribute('aria-controls');
        const $targetModalPanel = document.getElementById(ariaControls);
        const $modalCloseBtn = $targetModalPanel.querySelector('.js_modalClose');

        const $focusTrap = $targetModalPanel.querySelector('.js_focusTrap');


        // Open
        modalOpenBtn.addEventListener('click', () => {

            $targetModalPanel.classList.toggle('is_active');
            modalOpenBtn.setAttribute('aria-expanded', 'true');
            $targetModalPanel.setAttribute('aria-hidden', 'false');
            $html.style.overflow = 'hidden';
        });


        // Close
        $modalCloseBtn.addEventListener('click', () => {

            $targetModalPanel.classList.toggle('is_active');
            modalOpenBtn.setAttribute('aria-expanded', 'false');
            $targetModalPanel.setAttribute('aria-hidden', 'true');
            $html.style.overflow = 'auto';

        });


        // Close
        $targetModalPanel.addEventListener('click', (e) => {

            if (!e.target.closest('.modal_contents')) {
                $targetModalPanel.classList.toggle('is_active');
                modalOpenBtn.setAttribute('aria-expanded', 'false');
                $targetModalPanel.setAttribute('aria-hidden', 'true');
                $html.style.overflow = 'auto';
            }

        })


        // フォーカストラップ制御
        $focusTrap.addEventListener('focus', () => {
            $modalCloseBtn.focus();
        });

    });
})();
