// サイト全体で使用するJS

// ハンバーガーナビ
import Humburger from './modules/humburger';
const humburger = new Humburger('.humburger_toggle', '.humburger_menu');

// accordion
import Accordion from './modules/accordion';

// splide
import "./modules/splide";

// modal
import "./modules/modal";

// insta
import "./modules/getInsta";

// cache
import './modules/cache';
// input[type="date"]の初期値設定
// import "./modules/date";
