export default class Accordion {
    constructor(container) {
        this.container = container;
        this.buttons = container.querySelectorAll('.accordion_button');
        // this.buttonIcons = container.querySelectorAll('.accordion_button--icon');
        this.panels = container.querySelectorAll('.accordion_panel');
        this.expandedIndex = -1;

        // 初期状態をクラスに反映
        this.buttons.forEach((button, index) => {
            const panel = this.panels[index];
            const isExpanded = button.getAttribute('aria-expanded') === 'true';

            if (isExpanded) {
                this.expandedIndex = index;
            }

            // 初期のクラスを設定
            button.classList.toggle('is_expanded', isExpanded);
            panel.classList.toggle('is_open', isExpanded);
            panel.setAttribute('aria-hidden', !isExpanded);
        });

        // ボタンにイベントを設定
        this.buttons.forEach((button, index) => {
            button.addEventListener('click', () => this.toggle(index));
        });

        // this.buttonIcons.forEach((buttonIcon, index) => {
        //     buttonIcon.addEventListener('click', () => this.toggle(index));
        // });
    }

    toggle(index) {
        if (this.expandedIndex === index) {
            index = -1;
        }

        this.expandedIndex = index;

        this.buttons.forEach((button, i) => {
            const panel = this.panels[i];
            const isExpanded = this.expandedIndex === i;

            // クラスの切り替え
            button.classList.toggle('is_expanded', isExpanded);
            panel.classList.toggle('is_open', isExpanded);

            // aria-expanded と aria-hidden の設定
            button.setAttribute('aria-expanded', isExpanded);
            panel.setAttribute('aria-hidden', !isExpanded);
        });

        // this.buttonIcons.forEach((buttonIcon, i) => {
        //     const isExpanded = this.expandedIndex === i;
        //     buttonIcon.classList.toggle('is_expanded', isExpanded);
        // });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const AccordionJs = document.querySelector('.accordion');
    if (AccordionJs) {
        const containers = document.querySelectorAll('.accordion');
        containers.forEach(container => {
            new Accordion(container);
        });
    }
});
