const instaList = document.querySelector('#instagram');
const graph_api = 'https://graph.facebook.com/v21.0/';
const accessToken = 'EAAPIiAZAiRawBO58YLwOZB9sgo0SWyefl50hCCwZCBCHInA2UOFxsCRIENwXcjwchJ4W3JGrLcpjWaT9RmA4Benie0tamhAS8tvGw6PoZCJKhzQNXXyjBIcdVzhgTplB3EE57e5GZCSAZBO7Fmygnf5pNxJSFKI7TY6oBk9GA2iu3489kAy3qfSuXZBZCdDdfItw'; // アクセストークン
const businessID = '17841459057184333';
const fields = 'media{caption,media_url,permalink,timestamp,username}';
const url = graph_api + businessID + '?fields=' + fields + "&access_token=" + accessToken;

// 画面幅に応じて取得する件数を決定
function getMediaItemCount() {
    return window.innerWidth <= 768 ? 6 : 9;
}

if (instaList) {
    fetch(url)
        .then(response => response.json())
        .then(data => {
            const mediaItems = data.media.data; // APIから取得したメディアデータ
            const itemCount = getMediaItemCount(); // 画面幅に基づいて取得件数を設定

            // 画面幅に基づいて表示する件数を変更
            mediaItems.slice(0, itemCount).forEach(item => {
                const li = document.createElement('li');
                li.classList.add('insta_item');

                const a = document.createElement('a');
                a.href = item.permalink;
                a.classList.add('insta_link');
                a.target = '_blank'; // 新しいタブで開く

                // media_urlに.mp4が含まれているかをチェック
                if (item.media_url.includes('.mp4')) {
                    // 動画の場合
                    const video = document.createElement('video');
                    video.src = item.media_url;
                    video.controls = true; // 動画のコントロールを表示
                    video.classList.add('insta_video', 'img');
                    video.alt = item.caption || 'Instagram video';
                    a.appendChild(video);
                } else {
                    // 画像の場合
                    const img = document.createElement('img');
                    img.src = item.media_url;
                    img.alt = item.caption || 'Instagram image';
                    img.classList.add('insta_img', 'img');
                    a.appendChild(img);
                }

                li.appendChild(a);
                instaList.appendChild(li);
            });
        })
        .catch(error => {
            console.error('Error fetching Instagram data:', error);
        });
}
