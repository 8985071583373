import Splide from '@splidejs/splide';
import {
    AutoScroll
} from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/css';

if (document.querySelector('.kvSplide')) {
    const kvSplide = new Splide('.kvSplide', {
        arrows: false,
        pagination: false,
        type: 'loop',
        drag: 'free',
        snap: true,
        focus: 'center',
        pauseOnHover: false,
        perPage: 1,
        perMove: 1,
        padding: '16%',
        gap: "10px",
        autoScroll: {
            speed: 1,
        },
        breakpoints: {
            769: {
                gap: "16px",
            },
        }
    });

    kvSplide.mount({
        AutoScroll
    });
}

if (document.querySelector('.voiceSplide')) {
    const voiceSplide = new Splide('.voiceSplide', {
        pagination: true,
        arrows: false,
        drag: 'free',
        snap: true,
        focus: 0,
        omitEnd: true,
        perPage: 6,
        perMove: 1,
        rewind:true,
        gap: "10px",
        autoplay: true,
        breakpoints: {
            769: {
                perPage: 1,
                gap: "18px",
                padding: "16%"
            },
            1366: {
                perPage: 4,
                gap: "18px",
            },
        }
    });

    voiceSplide.mount({});
}

if (document.querySelector('.lowerSplide')) {
    const lowerSplide = new Splide('.lowerSplide', {
        arrows: false,
        pagination: false,
        type: 'loop',
        drag: 'free',
        snap: true,
        focus: 'center',
        pauseOnHover: false,
        perPage: 4,
        perMove: 1,
        padding: '16%',
        gap: "20px",
        autoScroll: {
            speed: 0.5,
        },
        breakpoints: {
            769: {
                gap: "16px",
                perPage: 1.5,
            },
        }
    });

    lowerSplide.mount({
        AutoScroll
    });
}
